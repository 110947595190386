import React, { useState, useEffect } from "react";
import "./signup.css";
import Login from "../login/login";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../booking/bookingcomp.css";
import { Link, useNavigate } from "react-router-dom";
import { signup, validateCode } from "../../service/users";

const Signup = ({ onClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [showSignup, setShowSignup] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // const handleLoginClick = () => {
  //   setShowLogin(true);
  //   setShowSignup(false);
  // };
  const closePopup = () => {
    setShowLogin(false);
    setShowSignup(false);
    onClose();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmitClick = async (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      setError("Please enter a valid email address");
      return;
    }
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.phone ||
      !formData.gender ||
      !formData.password ||
      !formData.confirmPassword
    ) {
      setError("All fields are required");
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    let result=await signup({
      lastname: formData.lastName,
      firstname: formData.firstName,
      email: formData.email,
      password: formData.password,
      phone: formData.phone,
      address: "",
      gender: formData.gender,
      nextOfKinName: "",
      nextOfKinPhone: "",
    });

    if(result !== 201){
      setError(result.data);
      return;
    }
    setError("");
    setShowSuccessPopup(true);
    setTimeout(() => {
      setShowSuccessPopup(false);
      console.log("show validation form")
      setShowValidation(true);
    }, 3000);
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const closeValidation = () => {
    setShowValidation(false);
    setShowSignup(true);
  };
  return (
    <>
      {showSignup && (
        <div className="overlay-container">
          <div className="blur-bg-overlay"></div>
          <div className="signup-container">
            <div className="welcomee-section">
              <h2>Create Account</h2>
              <p>
                To become a part of our community, please sign up using your
                personal information.
              </p>
            </div>
            <div className="form-content">
              <h2>SIGNUP</h2>
              <Link className="close-btn" to="/">Close</Link>
              <form onSubmit={handleSubmitClick}>
                {error && <div className="error-message">{error}</div>}
                <div className="input-field">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-field">
                  {" "}
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />{" "}
                </div>
                <div className="input-field">
                  {" "}
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleInputChange}
                  />{" "}
                </div>
                <div className="input-field">
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                <label htmlFor="gender">Gender</label>
                <div className="gender-options">
                  <input
                    type="radio"
                    name="gender"
                    value="Male"
                    id="male"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="male">Male</label>
                  <input
                    type="radio"
                    name="gender"
                    value="Female"
                    id="female"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="female">Female</label>
                </div>
                <div className="input-field password-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />{" "}
                  <span
                    onClick={togglePasswordVisibility}
                    className="password-toggle-icon"
                  >
                    {" "}
                    {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}
                  </span>{" "}
                </div>{" "}
                <div className="input-field password-field">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm password"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                  />
                  <span
                    onClick={toggleConfirmPasswordVisibility}
                    className="password-toggle-icon"
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <div className="policy-text">
                  <input type="checkbox" id="policy" />
                  <label htmlFor="policy">
                    {" "}
                    I agree to the{" "}
                    <a href="TandC" className="option">
                      Terms & Conditions
                    </a>
                  </label>
                </div>
                <button type="submit">Sign Up</button>
              </form>
              <div className="bottom-link">
                {" "}
                Already have an account?{" "}
                {/* <button onClick={handleLoginClick} id="login-link">
                  Login
                </button> */}
                <Link to="/login">Login</Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {showLogin && <Login onClose={closePopup} />}{" "}
      {showSuccessPopup && (
        <div className="popup-container">
          <div className="popup-content">
            {" "}
            <h3>Verification Code Sent</h3>{" "}
            <p>
              A verification code has been sent to your email/Phone. Please
              check your inbox.
            </p>
          </div>
        </div>
      )}
      {showValidation && <Validation onClose={closeValidation} />}
    </>
  );
};

const Validation = ({ onClose }) => {
  console.log("inside validation")
  const navigate = useNavigate();
  const [verCode, verCodeChanged] = useState();
  const [email, emailChanged] = useState();
  const handleProceedClick = async () => {
    if (verCode !== undefined && verCode.length > 0) {
  console.log("vercode length is > 0 and is not undefined")

      let result = await validateCode({
        email: email,
        verificationCode: verCode,
      });
      console.log("from validation function result: ", result)
      if (result === 201) {
        navigate("/admindashboard");
      } else if (result === 200) {
        navigate("/dashboard/profile");
      } else {
        alert("Invalid  Verification code");
      }
    }

  console.log("the end of validation code")
  };
  return (
    <div className="validation-overlay">
      <div className="validation-container">
        <button className="close-btn" onClick={onClose}>
          X
        </button>{" "}
        <p className="trip-detail-header">Email</p>
        <input
          className="reference-input"
          value={email}
          onChange={(chg) => emailChanged(chg.target.value)}
          placeholder="Enter Email"
        />
        <p className="trip-detail-header">Verification Code</p>
        <input
          className="reference-input"
          value={verCode}
          onChange={(chg) => verCodeChanged(chg.target.value)}
          placeholder="..."
        />
        <button className="scheduleButton" onClick={handleProceedClick}>
          Proceed
        </button>{" "}
      </div>
    </div>
  );
};
export default Signup;
