// apiService.js
import axios from "axios";
import { ROOT_URL } from "./base";
import { checkUnauthorisedError } from "./general";
import { getAdmin, getAuthHeaders } from "./users";
const BASE_URL = `${ROOT_URL}/route`;

export const fetchRoutes = async () => {
  try {
    var response = await axios.post(
      `${BASE_URL}/fetch-all-routes`,
      getAdmin(),
      {
        headers: getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return checkUnauthorisedError(error, []);
  }
};

export const fetchAvailableSeats = async (routeId, departureDateString) => {
  const body = {
    routeId: routeId,
    departureDateString: departureDateString,
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/fetch-available-seats`,
      body
    );
    return response.data;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status: 400,
      data: "Error Occurred while booking record",
    });
  }
};

export const fetchUniqueRoutes = (routes) => {
  if (!routes) return [];

  let _routes = [];
  for (let i = 0; i < routes.length; i++) {
    let location = routes[i].travellingFrom.trim().toUpperCase();
    if (!_routes.includes(location)) {
      _routes.push(location);
    }
  }
  return _routes;
};


export const fetchTravellingTo = (routes, travellingFrom) => {
  if (!routes || !travellingFrom) return [];

  return routes
    .filter(route => route.travellingFrom.trim().toLowerCase() === travellingFrom.trim().toLowerCase())
    .map(route => route.travellingTo);
};


export const fetchTravellingInfo = (routes, travellingTo, travellingFrom) => {
  for (let i = 0; i < routes.length; i++) {
    if (
      routes[i].travellingFrom === travellingFrom ||
      routes[i].travellingTo === travellingTo
    ) {
      return routes[i];
    }
  }
};

export const fetchFareAsync = async (travellingTo, travellingFrom) => {
  const routes = await fetchRoutes();
  for (let i = 0; i < routes.length; i++) {
    if (
      routes[i].travellingFrom === travellingFrom ||
      routes[i].travellingTo === travellingTo
    ) {
      return routes[i];
    }
  }
};

export const fetchVehicleImage = (vehicleType) => {
  if (vehicleType === "Bus") {
    return "toyota.jpeg";
  } else {
    return "sienna.jpeg";
  }
};

export const createRoute = async (reqData) => {
  try {
    const response = await axios.post(`${BASE_URL}/add-route`, reqData, {
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    console.log(error);
    return checkUnauthorisedError(error, {
      status: 400,
      data: "Error Occurred while booking record",
    });
  }
};

export const updateRoute = async (reqData) => {
  try {
    const response = await axios.post(`${BASE_URL}/update-route`, reqData, {
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    console.log(error);
    return checkUnauthorisedError(error, {
      status: 400,
      data: "Error Occurred while booking record",
    });
  }
};
export const deleteRoute = async (uid) => {
  try {
    const response = await axios.post(`${BASE_URL}/delete-route`, {uid:uid}, {
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    console.log(error);
    return checkUnauthorisedError(error, {
      status: 400,
      data: "Error Occurred while booking record",
    });
  }
};

// export const fetchJourney = async () => {
//   try {
//     var response = await axios.post(`${BASE_URL}/fetch-all-journey`,getAdmin(), {
//       headers: getAuthHeaders(),
//     });
//     return response.data;
//   } catch (error){
//     return checkUnauthorisedError(error, []);
//   }
// };

// export const createJourney = async (reqData) => {
//   try {
//     const response = await axios.post(`${BASE_URL}/create-journey`, reqData,{
//         headers: getAuthHeaders(),
//       });
//     return response;
//   } catch (error) {
//     console.log(error)
//     return checkUnauthorisedError(error, {
//       status:400,
//       data:'Error Occurred while booking record'
//     });
//   }

// };

// export const journeyStatusUpdate = async (reqData) => {
//     try {
//       const response = await axios.post(`${BASE_URL}/status-update`,reqData,{
//         headers: getAuthHeaders(),
//       });
//       return response;
//     } catch (error) {
//       return checkUnauthorisedError(error, {
//         status:400,
//         data:'Error Occurred while booking record'
//       });
//     }
//   };

export const bookSeat = async (routeId, seatNumber) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/book-seat`,
      { routeId, seatNumber },
      {
        headers: getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return checkUnauthorisedError(error, {
      status: 400,
      data: "Error occurred while booking the seat",
    });
  }
};

export const fetchSeatDetails = async (routeId) => {
  try {
    const response = await axios.get(`${BASE_URL}/seat-details/${routeId}`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return checkUnauthorisedError(error, {
      status: 400,
      data: "Error occurred while fetching seat details",
    });
  }
};
