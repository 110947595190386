import axios from "axios";
import { ROOT_URL } from "./base";
const BASE_URL = `${ROOT_URL}/general`;

export const contactus = async (reqData) => {
  const response = await axios.post(`${BASE_URL}/contactus`, reqData);
  return response.status;
};

export function gotoRoot() {
    if (window.location.href !== window.location.origin+"/" && window.location.href !== window.location.origin)
    window.location.href = window.location.origin;
}

export function gotoDashboard() {
  window.location.href = window.location.origin + "/dashboard/profile";
}
export function gotoAdminPage() {
  window.location.href = window.location.origin + "/admindashboard";
}
// export function gotoAdminPageFromJourney() {
//   window.location.href = window.location.origin + "/admindashboard/profile";
// }
export function showPassengers(journeyUid) {
  localStorage.adminSelection = "Passengers";
  localStorage.journeyUid = journeyUid;
  window.location.href = window.location.origin + "/admindashboard/passengers";
}

export function showPage(page) {
  localStorage.adminSelection = page;
  window.location.href = window.location.origin + "/admindashboard/" + page;
}
export function getPassenger() {
  const journeyUid = localStorage.journeyUid;
  if (
    localStorage.journeyUid !== undefined &&
    localStorage.journeyUid.length > 0
  ) {
    return journeyUid;
  } else {
    return undefined;
  }
}

export function checkUnauthorisedError(error, defaultVal) {
  try {
    if (error.response.status === 401) {
      localStorage.setItem("token", "");
      localStorage.setItem("isLoggedIn", false);
      if (window.location.href !== window.location.origin+"/" && window.location.href !== window.location.origin) {
        alert("Kindly login again, token has expired");
        gotoRoot();
      }
    } else {
      console.log(error);
    }
    return defaultVal;
  } catch {
    console.log(error);
  }
}
export function formatDateTime(_date) {
  var date = new Date(_date);
  var thisDate = date.toDateString();
  var suffix = date.getHours() >= 12 ? "PM" : "AM";
  var hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  hour = hour < 10 ? "0" + hour : hour;
  var min = date.getMinutes();
  var newTime = hour + ":" + min + suffix;

  let actual = thisDate + " " + newTime;
  if (actual.includes("NaN")) {
    return "-";
  } else {
    return actual;
  }
}
export function formatDate(_date) {
  var date = new Date(_date);
  var thisDate = date.toDateString();
  return thisDate;
}

export function formatTime(_date) {
  var date = new Date(_date);
  var suffix = date.getHours() >= 12 ? "PM" : "AM";
  var hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  hour = hour < 10 ? "0" + hour : hour;
  var min = date.getMinutes();
  var newTime = hour + ":" + min + suffix;

  return newTime;
}

export const checkCompleted = (status, current) => {
  if (status === "Awaiting") {
    return true;
  } else if (current === "In-Store") {
    if (status === "Awaiting" || status === "In-Store") {
      return true;
    }
  } else if (current === "Retrieved") {
    if (
      status === "Awaiting" ||
      status === "In-Store" ||
      status === "Retrieved"
    ) {
      return true;
    }
  }
};

export const getLastMaintenance = (maintenance) => {
  try {
    if (
      maintenance.lastMaintenanceApprovedBy !== undefined &&
      maintenance.lastMaintenanceApprovedBy.length > 0
    ) {
      return `${formatDate(maintenance.lastMaintenance)} (${
        maintenance.lastMaintenanceApprovedBy
      })`;
    } else {
      return `No-Maintenance`;
    }
  } catch {
    return `No-Maintenance`;
  }
};
